<template>
  <b-card class="delivery-item-container">
    <div class="delivery-header">
      <div class="d-flex justify-content-between align-items-start flex-wrap" >
        <div>
          <h3 class="d-flex align-items-start flex-wrap">
            <div class="mr-1" style="margin-bottom: 5px">
              Поставка #{{ dataDelivery.id }} от {{ dataDelivery.createAt }}
            </div>
            <b-badge style="font-size: 15px" :variant="getStatus.color">
              {{ getStatus.label }}
            </b-badge>
          </h3>
          <div class="mb-1 message-container"
               v-if="dataDelivery.message && dataDelivery.message.value">
        <span class="mr-1">
          <svg v-if="dataDelivery.message.type === 'error'" width="28" height="28" viewBox="0 0 28 28" fill="none"
               xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd"
      d="M1.16602 13.9993C1.16602 21.116 6.88268 26.8327 13.9993 26.8327C21.116 26.8327 26.8327 21.116 26.8327 13.9993C26.8327 6.88268 21.116 1.16602 13.9993 1.16602C6.88268 1.16602 1.16602 6.88268 1.16602 13.9993ZM3.49935 13.9993C3.49935 8.16602 8.16602 3.49935 13.9993 3.49935C19.8327 3.49935 24.4993 8.16602 24.4993 13.9993C24.4993 19.8327 19.8327 24.4993 13.9993 24.4993C8.16602 24.4993 3.49935 19.8327 3.49935 13.9993ZM15.166 13.9993V9.33268C15.166 8.63268 14.6993 8.16602 13.9993 8.16602C13.2993 8.16602 12.8327 8.63268 12.8327 9.33268V13.9993C12.8327 14.6993 13.2993 15.166 13.9993 15.166C14.6993 15.166 15.166 14.6993 15.166 13.9993ZM15.0493 19.1327C15.0493 19.2493 14.9327 19.366 14.816 19.4827C14.5827 19.716 14.3493 19.8327 13.8827 19.8327C13.5327 19.8327 13.2993 19.716 13.066 19.4827C12.8327 19.2493 12.716 19.016 12.716 18.666C12.716 18.5293 12.756 18.4327 12.7892 18.3526C12.8127 18.296 12.8327 18.2477 12.8327 18.1993C12.8327 18.0827 12.9493 17.966 13.066 17.8493C13.416 17.4993 13.8827 17.3827 14.3493 17.616C14.4077 17.616 14.4368 17.6452 14.466 17.6743C14.4952 17.7035 14.5243 17.7327 14.5827 17.7327C14.6993 17.7327 14.816 17.8493 14.816 17.8493C14.8743 17.9077 14.9035 17.966 14.9327 18.0243C14.9618 18.0827 14.991 18.141 15.0493 18.1993C15.166 18.316 15.166 18.5493 15.166 18.666C15.166 18.7243 15.1368 18.8118 15.1077 18.8993C15.0785 18.9868 15.0493 19.0743 15.0493 19.1327Z"
      fill="#EA5455"/>
</svg>
          <svg v-if="dataDelivery.message.type === 'warning'" width="27" height="24" viewBox="0 0 27 24" fill="none"
               xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd"
      d="M1.72876 22.9833C2.3121 23.3333 2.89543 23.45 3.47876 23.45H23.3121C24.2454 23.45 25.0621 23.1 25.6454 22.4C26.3454 21.8167 26.6954 20.8833 26.6954 19.95C26.6954 19.3667 26.5788 18.7833 26.2288 18.2L16.4288 1.75C15.8454 0.933333 15.1454 0.35 14.2121 0.116667C13.2788 -0.116667 12.3454 0 11.5288 0.466667C11.0621 0.7 10.5954 1.16667 10.3621 1.63333L0.445432 18.2C-0.487902 19.8333 0.0954318 22.05 1.72876 22.9833ZM2.54543 19.3667L12.3454 2.91667C12.4621 2.68333 12.5788 2.56667 12.8121 2.45C13.3954 2.21667 14.0954 2.33333 14.4454 2.91667L24.2454 19.3667C24.3621 19.6 24.3621 19.7167 24.3621 19.95C24.3621 20.3 24.2454 20.5333 24.0121 20.7667C23.7788 21 23.5454 21.1167 23.1954 21.1167H3.47877C3.3621 21.1167 3.12877 21.1167 3.0121 21C2.42877 20.65 2.19543 19.95 2.54543 19.3667ZM14.5621 12.95V8.28333C14.5621 7.58333 14.0954 7.11667 13.3954 7.11667C12.6954 7.11667 12.2288 7.58333 12.2288 8.28333V12.95C12.2288 13.65 12.6954 14.1167 13.3954 14.1167C14.0954 14.1167 14.5621 13.65 14.5621 12.95ZM14.5621 17.6167C14.5621 17.9667 14.4454 18.2 14.2121 18.4333C13.9788 18.6667 13.7454 18.7833 13.2788 18.7833C12.9288 18.7833 12.6954 18.6667 12.4621 18.4333C12.2288 18.2 12.1121 17.9667 12.1121 17.6167C12.1121 17.48 12.1521 17.3833 12.1853 17.3033C12.2087 17.2466 12.2288 17.1983 12.2288 17.15C12.2288 17.0333 12.3454 16.9167 12.4621 16.8C12.5788 16.6833 12.6954 16.5667 12.8121 16.5667C13.0454 16.45 13.2788 16.45 13.5121 16.45C13.5792 16.5171 13.6077 16.5456 13.642 16.5577C13.6673 16.5667 13.6959 16.5667 13.7454 16.5667C13.8038 16.5667 13.8329 16.5958 13.8621 16.625C13.8913 16.6542 13.9204 16.6833 13.9788 16.6833C14.0954 16.6833 14.2121 16.8 14.2121 16.8C14.3288 16.9167 14.4454 17.0333 14.4454 17.15C14.5621 17.2667 14.5621 17.5 14.5621 17.6167Z"
      fill="#FF9920"/>
</svg>
        </span>
            <span class="font-weight-bold"
                  :class="dataDelivery.message.type === 'error' ? 'text-danger' : 'text-warning'">
          {{ dataDelivery.message.value }}
        </span>
          </div>
        </div>
        <div class="marketplace mb-1">
          <div
              style="font-size: 20px"
              :style="{color: this.colorMP }"
          > {{ currMp }} </div>
          <div
              style="font-size: 14px"
          > {{ currWarehouse }} </div>
        </div>
      </div>
    </div>
    <div class="stats-container d-flex">
      <step-way
          v-for="(status, name, i) in dataDelivery.statuses"
          :key="i"
          variant="purple"
          :state="getCurrentStatus(name, i)"
          :name="status.label"
          :delivery="true"
          :date="status.date"
          :docName="status.docName"
          :docType="status.docType"
          :total="Object.keys(dataDelivery.statuses).length"
      />
    </div>
    <div class="mt-2 d-flex justify-content-between flex-wrap">
      <div class="mb-2">
        <div class="d-flex justify-content-between">
          <div class="mr-2">Стоимость работ</div>
          <div class="font-weight-bold">{{ dataDelivery.costOfWork }}</div>
        </div>
        <div class="my-1 d-flex justify-content-between">
          <div class="mr-2">Объем товара</div>
          <div class="font-weight-bold">
            <span>{{ dataDelivery.totalVolume }} м/куб; </span>
            <span v-if="dataDelivery.amountBox">коробов</span>
            <span v-if="dataDelivery.amountPallet">; паллет</span>
          </div>
        </div>
        <div class="d-flex justify-content-between">
          <div class="mr-2">Плановая дата поставки</div>
          <div class="font-weight-bold">{{ dataDelivery.date }}</div>
        </div>
      </div>
      <div class="">
        <div class="d-flex justify-content-between">
          <div class="mr-2">Товарных позиций</div>
          <div class="font-weight-bold">{{ dataDelivery.products.length }}</div>
        </div>
        <div class="my-1 d-flex justify-content-between">
          <div class="mr-2">Количество товаров</div>
          <div class="font-weight-bold">
            {{ dataDelivery.products.reduce((acc, prod) => acc + prod.amountBySize.amount, 0) }}
          </div>
        </div>
        <div class="d-flex align-items-end">
          <span class="pay-accept"
                v-b-popover.hover.top="getPopoverMpAccept"
                v-if="typeof dataDelivery.onlyFreeAcceptanceMP === 'number'">
            {{ `₽ ${dataDelivery.onlyFreeAcceptanceMP}` }}
          </span>
          <span class="free-accept"
                v-b-popover.hover.top="getPopoverMpAccept"
                v-else>
            ₽
          </span>
          <span v-b-popover.hover.top="`Поставка без транзита`" class="ml-2" v-if="dataDelivery.noTransit">
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path
    d="M16 9H12.82C10.5 9 11.3501 9 10 9C8.64987 9 10 9 7.18 9H4C3.67 9 2 8.9 2 7V6C2 4.17 3.54 4 4 4H14.18C14.6 5.16 15.7 6 17 6C17.7956 6 18.5587 5.68393 19.1213 5.12132C19.6839 4.55871 20 3.79565 20 3C20 2.20435 19.6839 1.44129 19.1213 0.878679C18.5587 0.31607 17.7956 0 17 0C15.7 0 14.6 0.84 14.18 2H4C2.39 2 0 3.06 0 6V7C0 9.94 2.39 11 4 11H7.18C10 11 8.7 11 10 11C11.3 11 11.5 11 12.82 11H16C16.33 11 18 11.1 18 13V14C18 15.83 16.46 16 16 16H5.82C5.4 14.84 4.3 14 3 14C2.20435 14 1.44129 14.3161 0.87868 14.8787C0.316071 15.4413 0 16.2044 0 17C0 17.7956 0.316071 18.5587 0.87868 19.1213C1.44129 19.6839 2.20435 20 3 20C4.3 20 5.4 19.16 5.82 18H16C17.61 18 20 16.93 20 14V13C20 10.07 17.61 9 16 9ZM17 2C17.2652 2 17.5196 2.10536 17.7071 2.29289C17.8946 2.48043 18 2.73478 18 3C18 3.26522 17.8946 3.51957 17.7071 3.70711C17.5196 3.89464 17.2652 4 17 4C16.7348 4 16.4804 3.89464 16.2929 3.70711C16.1054 3.51957 16 3.26522 16 3C16 2.73478 16.1054 2.48043 16.2929 2.29289C16.4804 2.10536 16.7348 2 17 2ZM3 18C2.73478 18 2.48043 17.8946 2.29289 17.7071C2.10536 17.5196 2 17.2652 2 17C2 16.7348 2.10536 16.4804 2.29289 16.2929C2.48043 16.1054 2.73478 16 3 16C3.26522 16 3.51957 16.1054 3.70711 16.2929C3.89464 16.4804 4 16.7348 4 17C4 17.2652 3.89464 17.5196 3.70711 17.7071C3.51957 17.8946 3.26522 18 3 18Z"
    fill="#7367F0"/>
</svg>
          </span>
          <span v-b-popover.hover.top="`Транзитная поставка`" class="ml-2" v-else>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path
    d="M16 9H12.82C12.4 7.84 11.3 7 10 7C8.7 7 7.6 7.84 7.18 9H4C3.67 9 2 8.9 2 7V6C2 4.17 3.54 4 4 4H14.18C14.6 5.16 15.7 6 17 6C17.7956 6 18.5587 5.68393 19.1213 5.12132C19.6839 4.55871 20 3.79565 20 3C20 2.20435 19.6839 1.44129 19.1213 0.87868C18.5587 0.316071 17.7956 0 17 0C15.7 0 14.6 0.84 14.18 2H4C2.39 2 0 3.06 0 6V7C0 9.94 2.39 11 4 11H7.18C7.6 12.16 8.7 13 10 13C11.3 13 12.4 12.16 12.82 11H16C16.33 11 18 11.1 18 13V14C18 15.83 16.46 16 16 16H5.82C5.4 14.84 4.3 14 3 14C2.20435 14 1.44129 14.3161 0.87868 14.8787C0.316071 15.4413 0 16.2044 0 17C0 17.7956 0.316071 18.5587 0.87868 19.1213C1.44129 19.6839 2.20435 20 3 20C4.3 20 5.4 19.16 5.82 18H16C17.61 18 20 16.93 20 14V13C20 10.07 17.61 9 16 9ZM17 2C17.2652 2 17.5196 2.10536 17.7071 2.29289C17.8946 2.48043 18 2.73478 18 3C18 3.26522 17.8946 3.51957 17.7071 3.70711C17.5196 3.89464 17.2652 4 17 4C16.7348 4 16.4804 3.89464 16.2929 3.70711C16.1054 3.51957 16 3.26522 16 3C16 2.73478 16.1054 2.48043 16.2929 2.29289C16.4804 2.10536 16.7348 2 17 2ZM3 18C2.73478 18 2.48043 17.8946 2.29289 17.7071C2.10536 17.5196 2 17.2652 2 17C2 16.7348 2.10536 16.4804 2.29289 16.2929C2.48043 16.1054 2.73478 16 3 16C3.26522 16 3.51957 16.1054 3.70711 16.2929C3.89464 16.4804 4 16.7348 4 17C4 17.2652 3.89464 17.5196 3.70711 17.7071C3.51957 17.8946 3.26522 18 3 18Z"
    fill="#7367F0"/>
</svg>
          </span>
        </div>
      </div>
    </div>
  </b-card>
</template>

<script>
import {
  BRow,
  BCol,
  BBadge,
  BCard,
  VBPopover,
} from 'bootstrap-vue';
import stepWay from "@/components/stepWay";
import {mapActions} from 'vuex'

export default {

  directives: {
    'b-popover': VBPopover
  },
  components: {
    BBadge,
    BCard,
    BRow,
    BCol,
    stepWay,
  },
  data() {
    return {
      statusesPresent: [
        {name: 'create', color: "light-primary"},
        {name: 'handling', color: "light-primary"},
        {name: 'packaging', color: "light-primary"},
        {name: 'delivery', color: "light-primary"},
        {name: 'accepted', color: "light-success"},
      ],
      showDetail: false,
      currentStatus: null,
      currMp: null,
      currWarehouse: null,
      colorMP: null,
    }
  },
  name: "cardDelivery",
  props: {
    dataDelivery: Object,
  },
  computed: {
    getPopoverMpAccept() {
      if (typeof this.dataDelivery.onlyFreeAcceptanceMP === "number")
        return `На указанную дату приемки коэффициент: ${this.dataDelivery.onlyFreeAcceptanceMP}`;
      return `На указанную дату приемка: бесплатно`

    },
    getStatus() {
      for (const name in this.dataDelivery.statuses) {
        if (name === this.dataDelivery.currentStatus) {
          this.dataDelivery.statuses[name].color = this.statusesPresent.find(st => st.name === name)?.color;
          return this.dataDelivery.statuses[name];
        }
      }
    },
  },
  methods: {
    getCurrentStatus(name, i) {
      if (this.dataDelivery.currentStatus === name) {
        if (Object.keys(this.dataDelivery.statuses).length - 1 === i) return 2;
        else {
          this.currentStatus = i;
          return 1;
        }
      }
      if (typeof this.currentStatus === "number" && this.currentStatus < i) {
        return 0;
      }
      return 2
    },
    ... mapActions('delivery', ['getMPByIDs'])
  },
  async mounted() {
    let mp = await this.getMPByIDs({
      idw: this.dataDelivery.marketplace.warehouseId,
      idm: this.dataDelivery.marketplace.id
    });

    if (mp) {
      this.currMp = mp.name;
      this.colorMP = mp.color
      this.currWarehouse = mp.warehouses[this.dataDelivery.marketplace?.warehouseId]?.name;
    }
  }
}

</script>

<style scoped>

.delivery-header {
  margin-bottom: 10px;
}

.free-accept {
  font-family: Roboto;
  background-color: #DCF7E7;
  text-align: center;
  width: 20px;
  height: 20px;
  border-radius: 20px;
  font-size: 15px;
  font-weight: 500;
  color: #28C76F;
  cursor: default;
}

.pay-accept {
  font-family: Roboto;
  background-color: #FCEAEA;
  text-align: center;
  height: 20px;
  border-radius: 20px;
  font-size: 15px;
  font-weight: 500;
  color: #EE7D7E;
  cursor: default;
  padding: 0 5px;
}

</style>
<template>
  <div class="delivery-container">
    <div class="d-flex flex-wrap justify-content-between key-header mb-2 align-items-start">
      <div class="mb-1">
        <h3>
          Список поставок
        </h3>
      </div>
      <b-button
          v-b-modal.add_products_to_delivery
          class="text-nowrap" variant="primary"
      >
        + Создать поставку
      </b-button>
    </div>
    <b-card body-class="p-0">
      <div class="p-2 d-flex flex-wrap justify-content-between align-items-start">
        <div class="mt-1 mr-1 d-flex align-items-center">
          <label class="mr-1">Поиск:</label>
          <b-form-input
              placeholder="по отгрузкам, товарам"
              type="text"
              class="d-inline-block"
              @input="findFilter"
          />
        </div>
        <div class="d-flex flex-wrap">
          <div class="mt-1 mr-1 d-flex align-items-center">
            <label class="mr-1">Сортировать:</label>
            <b-form-select
                v-model="dateFilter"
                :options="listDate"
            />
          </div>
          <div class="d-flex mt-1 align-items-center">
            <label class="mr-1">Статус:</label>
            <b-form-select
                v-model="statusFilter"
                :options="listStatus"
            />
          </div>
        </div>
      </div>
    </b-card>
    <div class="container-card">
      <card-delivery
          v-for="(item, i) in delivery"
          :key="i"
          :dataDelivery="item"
      />
    </div>
    <b-modal class="add-products-to-delivery"
             id="add_products_to_delivery"
             hide-header
             hide-footer
             modal-class="modal-primary"
             centered
             @hidden="clearSelected"
             size="xl"
    >
      <h4>Выберите товары для поставки</h4>
      <div class="px-2 pb-2 d-flex flex-wrap justify-content-between align-items-start">
        <div class="mt-1 mr-1 d-flex align-items-center">
          <label class="mr-1">Поиск:</label>
          <b-form-input
              placeholder="Поиск"
              type="text"
              class="d-inline-block"
              @input="findFilterProducts"
          />
        </div>
        <div class="d-flex flex-wrap">
          <div class="mt-1 mr-1 d-flex align-items-center">
            <label class="mr-1">Бренд:</label>
            <b-form-select
                v-model="brandFilter"
                :options="listAllBrands"
            />
          </div>
          <div class="d-flex mt-1 align-items-center">
            <label class="mr-1">Категория:</label>
            <b-form-select
                v-model="objectFilter"
                :options="listAllCategories"
            />
          </div>
        </div>
      </div>
      <table-load-products
          :findFilter="findFilterProducts"
          :brandFilter="brandFilter"
          :objectFilter="objectFilter"
          @details="showDetails"
      />
    </b-modal>
  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";
import cardDelivery from "@/components/delivery/cardDelivery";
import tableLoadProducts from "@/components/product/tableLoadProducts";
import {
  BButton,
  BCard,
  BCol,
  BModal,
  BFormInput,
  BFormGroup,
  BFormSelect,
  BRow
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BButton,
    BCol,
    BModal,
    BRow,
    BFormInput,
    BFormGroup,
    BFormSelect,
    tableLoadProducts,
    cardDelivery,
  },
  name: "Delivery",
  data() {
    return {
      findFilter: "по поставкам, товарам",
      listDate: [
        {value: '1', text: "дата приемки на мп"},
        {value: '2', text: "дата создания"},
        {value: '3', text: "количество товаров"},
      ],
      dateFilter: "1",
      listStatus: [
        {value: '1', text: "поставка создана"},
        {value: '2', text: "обработка товара"},
        {value: '3', text: "упаковка товара "},
        {value: '4', text: "поставка на мп"},
        {value: '5', text: "принято на мп"},
      ],
      findFilterProducts: "",
      brandFilter: "",
      objectFilter: "",
      statusFilter: "1",
    }
  },
  methods: {
    ...mapActions('products', ["clearSelected"]),
    showDetails(id) {
      this.currentDetailID = id;
      this.show = true
    },
  },
  computed: {
    ...mapState('products', ["listAllBrands", "listAllCategories"]),
    ...mapState('delivery', ["delivery"]),
  }
}

</script>

<style scoped>

</style>